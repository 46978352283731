import { FormLayout, SubmitButton, Textarea } from '@panwds/react-form';
import { Button, Sheet, SheetBody } from "@panwds/react-ui";
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslate } from "../../../../../customHooks";
import { isEmpty } from 'lodash';
import { validateAwsAccounts } from '../../../../../utils/validate';


export const FirewallAllowlistedAccounts = (props: any) => {
    const { close, fwRecord, onSubmit } = props;
    const translate = useTranslate();

    const formMethods = useForm({ defaultValues: fwRecord });
    const {
        formState: { dirtyFields, isValid }
    } = formMethods

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.endpointManagement.manageAllowlistedAccount`)}
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <div>
                                    <Textarea
                                        label={translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)}
                                        name="Firewall.AllowListAccounts"
                                        dataMetrics="cloudngfw-firewall-edit-allowlisted-aws-accounts"
                                        register={{ validate: validateAwsAccounts }}
                                        description={translate(`resources.firewallsV2.endpointManagement.firewallAllowlistedAwsAccountsEditDescription`)}
                                    />

                                    <div className="tw-flex tw-justify-end">
                                        <Button addClassName='tw-mr-2'
                                            appearance={"secondary"}
                                            onClick={() => props?.close()}>
                                            {translate("generic.cancel")}
                                        </Button>
                                        <SubmitButton disabled={isEmpty(dirtyFields)}>{translate(`generic.save`)}</SubmitButton>
                                    </div>
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};
